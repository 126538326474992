import classNames from 'classnames';
import React from 'react';

const Badge = React.forwardRef<
  HTMLSpanElement,
  React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLSpanElement>,
    HTMLSpanElement
  >
>(({ className, children }, ref) => {
  return (
    <span className={classNames('px-3 py-2 bg-primary-slate text-primary', className)} ref={ref}>
      {children}
    </span>
  );
});

export default Badge;
