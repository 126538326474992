import classNames from 'classnames';
import { Link, useTranslation } from 'gatsby-plugin-react-i18next';
import React, { useCallback, useEffect, useState } from 'react';
import { Burger } from '..';
import {
  logoAwumbaLongImg,
  logoAwumbaShortImg,
  logoAwumbaLongTextOnlyImg,
} from '../../../../images';
import { CenteredContainer } from '../../../centered-container';

function getMenuState(): 'none' | 'main' | 'alt' {
  return window.scrollY > 20 ? 'alt' : 'main';
}

const WithAnimationOnHover = ({
  children,
  animationClassNames,
}: {
  children: React.ReactElement;
  animationClassNames?: string;
}) => {
  return React.cloneElement(children, {
    className: classNames(
      `after:h-[1.5px] after:max-w-0 hover:after:max-w-full 
    after:block after:bg-white after:mt-1 after:transition-all after:duration-500`,
      animationClassNames
    ),
  });
};

function Menu() {
  const { t } = useTranslation();
  const [menuAltState, setMenuAltState] = useState<'none' | 'main' | 'alt'>(
    'none'
  );
  const [menuOpen, setMenuOpen] = useState(false);

  const scrollHandler = useCallback(
    (event: Event) => {
      setMenuAltState(getMenuState());
    },
    [setMenuAltState]
  );

  useEffect(() => {
    window.addEventListener('scroll', scrollHandler);
    setTimeout(() => setMenuAltState(getMenuState()));
    return () => window.removeEventListener('scroll', scrollHandler);
  }, []);

  let menuClass = '';
  let fullNavClass = '';
  let compactNavClass = '';
  let mobileMenuClass = '';
  let mobileCenteredContainerClass = '';
  let mobileLongLogoClass = '';

  switch (menuAltState) {
    case 'none':
      menuClass = 'hidden';
      mobileMenuClass = 'hidden';
      break;
    case 'main':
      menuClass = 'hidden md:block h-[var(--full-menu-height)]';
      compactNavClass = '!top-[calc(-1*var(--compact-menu-height))]';
      mobileMenuClass = '';
      mobileCenteredContainerClass = '';
      mobileLongLogoClass = '';
      break;
    case 'alt':
      menuClass =
        'hidden md:block h-[var(--compact-menu-height)] bg-[color:rgba(var(--color-secondary),.4)]';
      fullNavClass = '!top-[-100px]';
      mobileMenuClass = '';
      mobileCenteredContainerClass = '!px-0';
      mobileLongLogoClass = '-mt-10';
      break;
  }

  return (
    <>
      <div
        className={classNames(
          'fixed w-full top-0 text-white transition-all z-40',
          menuClass
        )}
      >
        <CenteredContainer className="relative h-full overflow-hidden !px-0">
          <nav
            className={classNames(
              'px-6 absolute top-0 flex h-1/2 w-full justify-between items-center translate-y-1/2 transition-all duration-500',
              fullNavClass
            )}
          >
            <Link className="h-full" to="/">
              <img className="h-full" src={logoAwumbaLongImg} alt="Logo" />
            </Link>
            <ul className="flex gap-20">
              <li>
                <WithAnimationOnHover>
                  <Link
                    activeClassName="text-primary after:bg-primary"
                    to="/careers/"
                  >
                    {t('menu.careers')}
                  </Link>
                </WithAnimationOnHover>
              </li>
              <li>
                <WithAnimationOnHover>
                  <Link
                    activeClassName="text-primary after:bg-primary"
                    to="/team/"
                  >
                    {t('menu.our-team')}
                  </Link>
                </WithAnimationOnHover>
              </li>
              <li>
                <Link className="button-primary" to="/#contact-us">
                  {t('menu.contact')}
                </Link>
              </li>
            </ul>
          </nav>
          <nav
            className={classNames(
              'absolute top-2 flex w-full flex-shrink-0 justify-between items-center flex-row transition-all duration-500',
              compactNavClass
            )}
          >
            {/* 1.5rem, must be same as padding in CenteredContainer! */}
            <Link className="w-6 h-6" to="/">
              <img className="h-full" src={logoAwumbaShortImg} alt="Logo" />
            </Link>
            <Burger
              className="w-6"
              onClick={() => setMenuOpen(true)}
              role="button"
            />
          </nav>
        </CenteredContainer>
      </div>
      <div
        className={classNames(
          'md:hidden fixed w-full top-0 text-white transition-all z-40 h-[var(--compact-menu-height)] bg-[color:rgba(var(--color-secondary),.4)]',
          mobileMenuClass
        )}
      >
        <CenteredContainer
          className={classNames(
            'h-full flex items-center overflow-hidden transition-all duration-500',
            mobileCenteredContainerClass
          )}
        >
          <nav
            className={classNames(
              'flex w-full flex-shrink-0 justify-between items-center flex-row'
            )}
          >
            {/* 1.5rem, must be same as padding in CenteredContainer! */}
            <Link className="h-6 flex" to="/">
              <img className="h-full" src={logoAwumbaShortImg} alt="Logo" />
              <img
                className={classNames(
                  'h-full transition-all duration-500',
                  mobileLongLogoClass
                )}
                src={logoAwumbaLongTextOnlyImg}
                alt="Logo"
              />
            </Link>
            <Burger
              className="w-6"
              onClick={() => setMenuOpen(true)}
              role="button"
            />
          </nav>
        </CenteredContainer>
      </div>
      {menuOpen && (
        <div
          className="fixed w-full h-full top-0 z-50 bg-white flex justify-end px-6 py-10 md:py-20"
          onClick={() => setMenuOpen(false)}
        >
          <ul className="flex flex-col items-end gap-10 md:gap-20 text-xl md:text-5xl text-end mr-8 md:mr-28">
            {[
              ['/careers', 'menu.careers'],
              ['/team', 'menu.our-team'],
              ['/#contact-us', 'menu.contact'],
            ].map(([url, label]) => (
              <li className="flex" key={label}>
                <WithAnimationOnHover animationClassNames="after:h-[3px] after:bg-black">
                  <Link
                    activeClassName="text-primary after:!bg-primary"
                    to={url}
                  >
                    {t(label)}
                  </Link>
                </WithAnimationOnHover>
              </li>
            ))}
          </ul>
          <i
            className="before:font-icons before:content-['\e5cd'] before:text-4xl md:before:text-7xl"
            title="Close"
            role="button"
          ></i>
        </div>
      )}
    </>
  );
}

export default Menu;
