import classNames from 'classnames';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import React, { useCallback, useRef, useState } from 'react';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useForm } from 'react-hook-form';
import { EMAIL_VALIDATION_RULES } from '../../../shared/consts';
import { Input } from '../input';
import { TextArea } from '../text-area';

export interface ContactFormProps
  extends React.HTMLAttributes<HTMLFormElement> {
  mode: 'contact' | 'career';
  subject?: string;
  onFormSubmitted?: () => void;
}

function ContactForm({
  className,
  mode,
  subject,
  onFormSubmitted,
  children,
  ...rest
}: ContactFormProps) {
  const { t } = useTranslation();
  const fakeInputRef = useRef<HTMLInputElement>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const { handleSubmit, register, reset, resetField, formState } = useForm();
  const [token, setToken] = useState('');
  const [fileName, setFileName] = useState<string | undefined>('');
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(0);
  const {
    ref: fileRef,
    onChange: fileOnChange,
    ...fileRest
  } = register('file', {
    validate: {
      lessThanSize: (files) =>
        files?.length
          ? files[0]?.size < EMAIL_VALIDATION_RULES.file.maxSize ||
            `Max ${EMAIL_VALIDATION_RULES.file.maxSize} bytes`
          : undefined,
      acceptedFormats: (files) =>
        files?.length
          ? EMAIL_VALIDATION_RULES.file.acceptedMimeTypes.includes(
              files[0]?.type
            ) || 'Wrong file type'
          : undefined,
    },
  });

  const handleVerify = useCallback(
    (token: string) => {
      setToken(token);
    },
    [setToken]
  );

  const onSubmit = async (data: any) => {
    /* BOTs will try to fill any input, but we want this to be empty :) */
    if (fakeInputRef.current?.value) {
      return;
    }
    const formData = new FormData();
    formData.append('name', data.name);
    formData.append('email', data.email);
    formData.append('phone', data.phone);
    formData.append('company', data.company);
    formData.append('message', data.message);
    formData.append('token', token);
    formData.append('kind', mode);
    subject && formData.append('subject', subject);
    data.file?.length && formData.append('file', data.file[0]);
    try {
      setToken('');
      const response = await fetch(process.env.GATSBY_EMAIL_API_URL as any, {
        method: 'POST',
        body: formData,
      });
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      reset();
      onFormSubmitted?.();
      alert(t('components.contact-form.after-submitting'));
    } catch (e) {
      console.error(e);
      alert(t('components.contact-form.after-submitting-error'));
    } finally {
      setRefreshReCaptcha((v) => +!v);
    }
  };

  const errors = Object.entries(formState.errors);

  const getErrorMessage = useCallback(
    (type: string) => t(`components.contact-form.errors.${type}`),
    [t]
  );

  return (
    <>
      <form
        className={classNames(
          'flex md:grid flex-col grid-cols-2 gap-x-12 gap-y-10 md:gap-y-20',
          className
        )}
        onSubmit={handleSubmit(onSubmit)}
        {...rest}
      >
        <Input
          id="name-input"
          label={`${t('components.contact-form.name')}*`}
          placeholder={t('components.contact-form.name-placeholder')}
          {...register('name', {
            required: EMAIL_VALIDATION_RULES.name.required,
            maxLength: EMAIL_VALIDATION_RULES.name.maxLength,
          })}
        />
        <Input
          id="email-input"
          label={`${t('components.contact-form.email')}*`}
          placeholder={t('components.contact-form.email-placeholder')}
          {...register('email', {
            required: EMAIL_VALIDATION_RULES.email.required,
            maxLength: EMAIL_VALIDATION_RULES.email.maxLength,
            pattern: EMAIL_VALIDATION_RULES.email.regex,
          })}
        />
        <Input
          id="phone-input"
          label={t('components.contact-form.phone')}
          placeholder={t('components.contact-form.phone-placeholder')}
          {...register('phone', {
            required: EMAIL_VALIDATION_RULES.phone.required,
            maxLength: EMAIL_VALIDATION_RULES.phone.maxLength,
          })}
        />
        {mode === 'contact' && (
          <Input
            id="company-input"
            label={t('components.contact-form.company')}
            placeholder={t('components.contact-form.company-placeholder')}
            {...register('company', {
              required: EMAIL_VALIDATION_RULES.company.required,
              maxLength: EMAIL_VALIDATION_RULES.company.maxLength,
            })}
          />
        )}
        <TextArea
          id="message-input"
          className="col-span-2"
          autoHeight
          label={`${t('components.contact-form.message')}*`}
          placeholder={t('components.contact-form.message-placeholder')}
          {...register('message', {
            required: EMAIL_VALIDATION_RULES.message.required,
            maxLength: EMAIL_VALIDATION_RULES.message.maxLength,
          })}
        />
        {mode === 'career' && (
          <div className="col-span-2 ">
            <label className="flex flex-col">
              {t('components.contact-form.file')} (
              {t('components.contact-form.file-description')})
              <button
                className="inline-flex self-start button-primary-inverted mt-4"
                type="button"
                onClick={() => fileInputRef.current?.click()}
              >
                {t('components.contact-form.choose-file')}
              </button>
              <input
                className="hidden"
                {...fileRest}
                ref={(e) => {
                  fileRef(e);
                  fileInputRef.current = e;
                }}
                onChange={(e) => {
                  fileOnChange(e);
                  setFileName(e.currentTarget.value?.split(/(\\|\/)/g).pop());
                }}
                type="file"
              />
            </label>
            {fileName && (
              <span className="flex items-center mt-4">
                {fileName}
                <button
                  className="material-symbols-outlined"
                  type="button"
                  onClick={() => {
                    resetField('file');
                    setFileName('');
                  }}
                >
                  close
                </button>
              </span>
            )}
          </div>
        )}
        {!!errors.length && (
          <ul className="col-span-2 text-red-700 text-sm md:text-base">
            {errors.map(([name, err], idx) => (
              <li key={idx}>
                {t(`components.contact-form.${name}`)}:{' '}
                {getErrorMessage(err?.type as any)}
              </li>
            ))}
          </ul>
        )}
        <button
          className="button-primary justify-self-start"
          type="submit"
          disabled={!token}
        >
          {t('components.contact-form.submit')}
        </button>
        <input className="w-0 h-0" type="text" ref={fakeInputRef} />
      </form>
      <GoogleReCaptcha
        onVerify={handleVerify}
        refreshReCaptcha={refreshReCaptcha}
        action="email"
      />
    </>
  );
}

export default ContactForm;
