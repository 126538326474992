import { Link, useTranslation } from 'gatsby-plugin-react-i18next';
import { t } from 'i18next';
import React, { SyntheticEvent } from 'react';
import { PositionIcon, Tag } from '../..';
import { iconPlaceImg } from '../../../images';
import { Career } from '../../../models';

export interface CareerPanelProps {
  career: Career;
}

const CareerPanel = ({ career }: CareerPanelProps) => {
  const { t } = useTranslation();
  return (
    <section className="bg-white my-3 border border-[color:rgb(var(--panel-border))] rounded-lg tile-shadow">
      <Link
        className="block md:flex justify-between p-[13px] md:py-4 md:px-8"
        to={career.id}
      >
        <div>
          <Tag className="bg-[color:rgba(98,191,203,.2)] inline-flex items-center text-[.6rem] md:text-base">
            <PositionIcon
              className="w-3 md:w-4 h-3 md:h-4 mr-1 md:mr-2 inline-block"
              position={career.position}
            />
            {career.position}
          </Tag>
          <h3 className="text-lg md:text-2xl text-header font-semibold mt-3 md:mt-4">
            {career.name}
          </h3>
          <div className="text-[.6rem] md:text-sm mt-2 md:mt-4 flex items-start md:items-center">
            <i
              className="bg-contain bg-no-repeat bg-right h-3 md:h-4 w-3 md:w-4 inline-block mr-1 md:mr-2"
              style={{ backgroundImage: `url("${iconPlaceImg}")` }}
            />
            {career.location}
          </div>
        </div>
        <div className="md:flex flex-col items-end justify-between mt-3 md:mt-0">
          <div className="text-primary md:text-2xl font-semibold">
            {career.salary}
          </div>
          <button className="hidden md:block button-primary-inverted">
            {t('shared.apply')}
          </button>
        </div>
      </Link>
    </section>
  );
};

export default CareerPanel;
