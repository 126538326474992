import classNames from 'classnames';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Footer, Menu } from '../components';
import { ScrollContext } from './hooks';

export interface LayoutProps {
  children: React.ReactNode;
}

function Layout({ children }: LayoutProps) {
  const [canScroll, setCanScroll] = useState(true);
  return (
    <ScrollContext.Provider value={{ setCanScroll }}>
      <Helmet
        htmlAttributes={{
          class: classNames('scroll-smooth', !canScroll && 'overflow-hidden'),
        }}
      />
      <div className="text-main font-sans relative">
        <Menu />
        <main
          className={`mt-[var(--compact-menu-height)] md:mt-[var(--full-menu-height)]`}
        >
          {children}
        </main>
        <Footer />
      </div>
    </ScrollContext.Provider>
  );
}

export default Layout;
