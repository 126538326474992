import classNames from 'classnames';
import React from 'react';
import { InputWrapper } from '../input-wrapper';

export interface InputProps extends React.HTMLAttributes<HTMLInputElement> {
  label: React.ReactNode;
}

const Input = React.forwardRef(
  (
    { className, label, ...rest }: InputProps,
    ref: React.ForwardedRef<HTMLInputElement>
  ) => {
    const { id } = rest;
    return (
      <InputWrapper
        label={label}
        className={classNames('material-input-wrapper', className)}
        inputId={id}
      >
        <input
          ref={ref}
          {...rest}
          aria-labelledby={`${id}-label`}
        />
      </InputWrapper>
    );
  }
);

export default Input;
