import React, { useContext } from 'react';

export interface ScrollContextContract {
  setCanScroll: (canScroll: boolean) => void;
}

export const ScrollContext = React.createContext<ScrollContextContract>({
  setCanScroll: () => {
    throw new Error('wrap your content with ScrollContext.Provider');
  },
});

export function useScrollContext(): ScrollContextContract {
  return useContext(ScrollContext);
}
