import React from 'react';
import { Skill } from '../../../models';

export interface SkillRatingProps {
  skill: Skill;
  levelName: string;
  className?: string;
}

const SkillRating = ({ skill, levelName, className }: SkillRatingProps) => {
  return (
    <div className={className}>
      <ul>
        {[...Array(5).keys()].map((b) => (
          <li
            className={`inline-block w-[10px] h-[10px] rounded-full mr-[10px] ${
              b < skill.rating
                ? 'bg-primary'
                : 'bg-[color:rgba(var(--panel-border))]'
            }`}
            key={b}
          ></li>
        ))}
      </ul>
      <h6 className="text-lg font-semibold my-2">{skill.name}</h6>
      <p className="text-sm text-[#9bb1c3]">{levelName}</p>
    </div>
  );
};

export default SkillRating;
