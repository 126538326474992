import React from 'react';

export interface WorkProcessTileProps {
  children: React.ReactNode;
}

function WorkProcessTile({ children }: WorkProcessTileProps) {
  return <div className="border border-transparent rounded-lg tile-shadow h-[164px] lg:h-auto lg:max-h-[360px] overflow-hidden">{children}</div>;
}

export default WorkProcessTile;
