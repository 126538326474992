import React from 'react';
import Development from './icon_dev.inline.svg';
import DevOps from './icon_devops.inline.svg';
import ProjectManagement from './icon_project_management.inline.svg';
import QA from './icon_qa.inline.svg';
import UX from './icon_ux.inline.svg';
import VisualDesigner from './icon_visual_designer.inline.svg';
import Generic from './icon_generic.inline.svg';

export interface PositionIconProps {
  position: string;
  className?: string;
}

const ALL_ICONS: { [key: string]: React.FunctionComponent } = {
  development: Development,
  devops: DevOps,
  projectmanagement: ProjectManagement,
  qa: QA,
  ux: UX,
  visualdesigner: VisualDesigner,
};

const PositionIcon = ({ position, className }: PositionIconProps) => {
  const component =
    ALL_ICONS[position.replace(/\s*/gi, '').toLowerCase()] ?? Generic;
  return React.createElement(component as any, { className });
};

export default PositionIcon;
