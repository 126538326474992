import classNames from 'classnames';
import React from 'react';

const Tag = ({
  className,
  children,
  ...rest
}: React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLSpanElement>,
  HTMLSpanElement
>) => {
  return (
    <span
      className={classNames(
        'inline-block py-[6px] md:py-2 px-[9px] md:px-4 rounded-lg',
        className
      )}
      {...rest}
    >
      {children}
    </span>
  );
};

export default Tag;
