import classNames from 'classnames';
import { graphql, StaticQuery } from 'gatsby';
import { Link } from 'gatsby-plugin-react-i18next';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  bgMapImg,
  bgMapMobileImg,
  iconPlaceImg,
  logoAwumbaLongImg,
  logoAwumbaShortImg,
} from '../../../../images';
import { Badge } from '../../../badge';
import {
  CenteredContainer,
  NarrowCenteredContainer,
} from '../../../centered-container';

const BG_MAP_COMMON_CLASS =
  'bg-cover bg-no-repeat absolute top-0 bottom-0 left-0 right-0 -z-10';

function Footer() {
  const { t } = useTranslation();

  return (
    <StaticQuery
      query={graphql`
        query AllCareersQuery {
          allContentfulCareer(filter: { visible: { eq: true } }) {
            totalCount
          }
        }
      `}
      render={(data) => (
        <footer>
          <section className="padded-section relative" id="find-us">
            <div
              className={classNames('hidden md:block', BG_MAP_COMMON_CLASS)}
              style={{ backgroundImage: `url("${bgMapImg}")` }}
            />
            <div
              className={classNames('block md:hidden', BG_MAP_COMMON_CLASS)}
              style={{ backgroundImage: `url("${bgMapMobileImg}")` }}
            />
            <NarrowCenteredContainer className="grid grid-cols-10 gap-x-4">
              <header className="text-2xl md:text-5xl font-secondary text-primary col-span-10 md:col-span-3 mb-6 md:mb-0">
                {t('index.find-us.header')}
              </header>
              <i
                className="bg-contain bg-no-repeat bg-right h-8 col-start-2 md:col-start-4"
                style={{ backgroundImage: `url("${iconPlaceImg}")` }}
              ></i>
              <div className="col-span-3 md:col-span-2">
                <p className="text-white md:text-2xl">Houston</p>
                <p className="mt-2 md:mt-4 text-sm md:text-lg text-[color:#FFFFFFB4]">
                  USA
                </p>
              </div>
              <i
                className="bg-contain bg-no-repeat bg-right h-8 md:col-start-8"
                style={{ backgroundImage: `url("${iconPlaceImg}")` }}
              ></i>
              <div className="col-span-4 md:col-span-2">
                <p className="text-white md:text-2xl">Cracow</p>
                <p className="mt-2 md:mt-4 text-sm md:text-lg text-[color:#FFFFFFB4]">
                  Poland
                </p>
              </div>
            </NarrowCenteredContainer>
          </section>
          <section className="bg-awumba-gray pt-10 md:pt-28 text-[color:rgba(var(--color-footer-text))]">
            <NarrowCenteredContainer className="grid grid-cols-2 md:grid-cols-10 gap-4">
              <div className="col-span-2 md:col-span-3">
                <img
                  className="block md:hidden max-w-[36px]"
                  src={logoAwumbaShortImg}
                  alt="Logo"
                />
                <img
                  className="hidden md:block max-w-[210px]"
                  src={logoAwumbaLongImg}
                  alt="Logo"
                />
                <p className="text-white uppercase mt-6 md:mt-10 font-secondary font-semibold mb-8 md:mb-0">
                  {t('footer.intro-text-1')}
                </p>
                <p className="hidden md:block mt-7">
                  {t('footer.intro-text-2')}
                </p>
              </div>
              <div className="md:col-span-3 md:col-start-5 text-sm md:text-base">
                <p className="text-white font-secondary font-semibold">
                  {t('footer.company')}
                </p>
                <nav className="mt-7 md:mt-10">
                  <ul>
                    <li>
                      <Link to="/#who-we-are">{t('footer.about')}</Link>
                    </li>
                    <li className="mt-3">
                      <Link to="/team">{t('footer.our-team')}</Link>
                    </li>
                    <li className="mt-3">
                      <Link to="/careers">
                        {t('footer.careers')}
                        {data.allContentfulCareer.totalCount && (
                          <Badge className="mx-2 uppercase text-[.5rem] md:[font-size:inherit]">
                            {t('footer.hiring-info')}
                          </Badge>
                        )}
                      </Link>
                    </li>
                    <li className="mt-3">
                      <Link to="/#contact-us">{t('footer.contact')}</Link>
                    </li>
                  </ul>
                </nav>
              </div>
              <div className="md:col-span-3 text-sm md:text-base">
                <p className="text-white font-secondary font-semibold">
                  {t('footer.contact-info')}
                </p>
                <p className="mt-7 md:mt-10">AWUMBA SOLUTIONS LLC</p>
                <p className="mt-3">
                  6201&nbsp;Bonhomme&nbsp;Rd Ste&nbsp;476N, Houston,
                  TX&nbsp;77036
                </p>
                <p className="mt-3">
                  <a className="mb-4" href="phone:+48 567 587 875">
                    +48 567 587 875
                  </a>
                </p>
                <p className="mt-3">
                  <a className="mb-14" href="phone:+48 567 587 875">
                    +48 567 587 875
                  </a>
                </p>
                <p className="mt-3">
                  <a className="mb-16" href="mailto:awumba@awumba-us.com">
                    awumba@awumba-us.com
                  </a>
                </p>
              </div>
            </NarrowCenteredContainer>
            <CenteredContainer className="flex justify-end mt-8 md:mt-0">
              <a
                className="h-16 w-16 rounded-lg bg-opacity-20 bg-black text-white flex justify-center items-center animate-bounce"
                href="#banner"
              >
                <span className="material-symbols-outlined text-[44px] leading-[44px]">
                  arrow_upward
                </span>
              </a>
            </CenteredContainer>
            <div className="border-t border-[color:#707070] mt-4 md:mt-14 py-7 text-xs md:text-base">
              <div className="mx-auto w-fit grid gap-4 md:block">
                <span className="md:border-r border-[color:#707070] justify-self-center md:pr-12">
                  {t('footer.copyright')}
                </span>
                <span className="md:ml-12 row-start-1 justify-self-center">
                  {t('footer.terms-and-conditions')}
                </span>
              </div>
            </div>
          </section>
        </footer>
      )}
    />
  );
}

export default Footer;
