import classNames from 'classnames';
import React from 'react';

export interface SectionHeaderProps {
  children: React.ReactNode;
  className?: string;
}

function SectionHeader({ children, className }: SectionHeaderProps) {
  return (
    <header
      className={classNames(
        `text-xs md:text-base block w-fit mx-auto font-secondary uppercase font-black 
        bg-[color:rgba(var(--color-primary),.2)] text-primary px-3 md:px-8 py-1 md:py-2`,
        className
      )}
    >
      {children}
    </header>
  );
}

export default SectionHeader;
