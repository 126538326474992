import React from 'react';
import WorkProcessTile from './work-process-tile';

export interface WorkProcessTextTileProps {
  nb: string;
  title: React.ReactNode;
  children: React.ReactNode;
}

function WorkProcessTextTile({
  nb,
  title,
  children,
}: WorkProcessTextTileProps) {
  return (
    <WorkProcessTile>
      <div className="flex md:block gap-3 px-4 md:px-10 py-4 md:py-9 bg-white h-full">
        <div className="text-lg md:text-4xl text-primary font-semibold">{nb}</div>
        <div>
          <div className="text-lg md:text-2xl lg:text-4xl text-header font-semibold md:mt-4">
            {title}
          </div>
          <div className="text-xs xl:text-lg text-header mt-4">{children}</div>
        </div>
      </div>
    </WorkProcessTile>
  );
}

export default WorkProcessTextTile;
