import React from 'react';
import WorkProcessTile from './work-process-tile';

export interface WorkProcessImgTileProps {
  imgSrc: string;
  alt?: string;
}

function WorkProcessImgTile({ imgSrc, alt }: WorkProcessImgTileProps) {
  return (
    <WorkProcessTile>
      <img className="w-full h-full object-cover object-top transition-transform duration-300 hover:scale-110" src={imgSrc} alt={alt} />
    </WorkProcessTile>
  );
}

export default WorkProcessImgTile;
