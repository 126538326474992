import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NarrowCenteredContainer } from '../../centered-container';
import Contact from './icon_contact.inline.svg';
import Interview from './icon_interview.inline.svg';
import Message from './icon_message.inline.svg';
import Offer from './icon_offer.inline.svg';

const Dot = () => (
  <i className="w-[7px] md:w-[11px] h-[7px] md:h-[11px] bg-primary rounded-full" />
);

const Ellipsis = ({ className }: { className?: string }) => (
  <div
    className={classNames(
      'inline-flex flex-col md:flex-row gap-[12px] md:gap-[19px] md:mt-[20px] justify-self-center md:justify-self-auto',
      className
    )}
  >
    <Dot />
    <Dot />
    <Dot />
  </div>
);

interface ProcessItemProps {
  icon: React.ReactNode;
  title: React.ReactNode;
  text: React.ReactNode;
}

const ProcessItem = ({ icon, title, text }: ProcessItemProps) => (
  <div className={`flex flex-col basis-full items-center`}>
    <div className="flex items-center relative">{icon}</div>
    <h6 className="mt-4 md:mt-8 font-semibold text-lg md:text-2xl text-center">
      {title}
    </h6>
    <p className="text-sm md:text-lg text-center mt-4 md:mt-8">{text}</p>
  </div>
);

const RecruitmentProcess = () => {
  const { t } = useTranslation();
  return (
    <section className="padded-section">
      <NarrowCenteredContainer>
        <h2 className="text-2xl md:text-5xl text-center md:text-left font-secondary text-header">
          {t('components.recruitment-process.title')}
        </h2>
        <div className="grid md:grid-cols-[1fr_auto_1fr_auto_1fr_auto_1fr] gap-y-8 mt-14 md:mt-28">
          <ProcessItem
            icon={<Contact />}
            title={t('components.recruitment-process.step-1-title')}
            text={t('components.recruitment-process.step-1-text')}
          />
          <Ellipsis />
          <ProcessItem
            icon={<Message />}
            title={t('components.recruitment-process.step-2-title')}
            text={t('components.recruitment-process.step-2-text')}
          />
          <Ellipsis />
          <ProcessItem
            icon={<Interview />}
            title={t('components.recruitment-process.step-3-title')}
            text={t('components.recruitment-process.step-3-text')}
          />
          <Ellipsis />
          <ProcessItem
            icon={<Offer />}
            title={t('components.recruitment-process.step-4-title')}
            text={t('components.recruitment-process.step-4-text')}
          />
        </div>
      </NarrowCenteredContainer>
    </section>
  );
};

export default RecruitmentProcess;
