import classNames from 'classnames';
import React from 'react';

const CenteredContainer = React.forwardRef<
  HTMLDivElement,
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
>(({ children, className }, ref) => {
  return (
    <div
      className={classNames('max-w-xxl md:mx-auto px-6', className)}
      ref={ref}
    >
      {children}
    </div>
  );
});

export default CenteredContainer;
