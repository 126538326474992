import classNames from 'classnames';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useScrollContext } from '../../../layouts/hooks';
import { TeamMember } from '../../../models';
import { PositionIcon } from '../../position-icon';

export interface TeamMemberPanelProps {
  member: TeamMember;
}

interface InnerProps {
  member: TeamMember;
  isFullScreenPreview?: boolean;
}

const checkIsMobile = () => window.innerWidth < 768;

const Inner = ({ member, isFullScreenPreview }: InnerProps) => (
  <div
    className={classNames(
      `flex flex-col items-center md:absolute p-4 rounded-lg group transition-all
md:hover:-top-4 md:hover:-left-4 md:hover:-right-4 md:hover:z-10 md:hover:bg-white md:hover:tile-shadow`,
      isFullScreenPreview && 'text-white'
    )}
  >
    <div className="relative w-full aspect-square">
      <img
        className={classNames(
          `w-full aspect-square object-center rounded-full grayscale md:group-hover:grayscale-0`,
          isFullScreenPreview && 'border-white border !grayscale-0'
        )}
        src={member.photoUrl}
      />
      <div
        className={`absolute md:top-0 bottom-0 md:bottom-[unset] right-0 bg-primary 
h-[20%] md:h-[55px] w-[20%] md:w-[55px] 
rounded-full flex items-center justify-center text-white`}
      >
        <PositionIcon
          className="w-[60%] md:w-[unset] h-[60%] md:h-[unset]"
          position={member.position}
        />
      </div>
    </div>
    <h5
      className={classNames(
        `font-secondary text-lg md:text-2xl mt-4 md:mt-6 md:group-hover:font-black`,
        !isFullScreenPreview && 'text-header',
        isFullScreenPreview && '!text-2xl !mt-6'
      )}
    >
      {member.firstName}
    </h5>
    <div
      className={classNames(
        `first-letter:font-semibold text-primary mt-2 text-xs md:text-base`,
        isFullScreenPreview && '!text-base !mt-3'
      )}
    >
      {member.position}
    </div>
    <div
      className={classNames(
        `mt-3 md:mt-5 md:group-hover:line-clamp-none 
    md:group-hover:px-4 md:group-hover:pb-8 text-center text-xs md:text-sm`,
        !isFullScreenPreview && 'line-clamp-3',
        isFullScreenPreview && '!text-sm !mt-4'
      )}
    >
      {member.bio}
    </div>
  </div>
);

export const TeamMemberPanel = ({ member }: TeamMemberPanelProps) => {
  const [fullScreenOpen, setFullScreenOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const { setCanScroll } = useScrollContext();
  const toggleFullScreen = useCallback(
    (open: boolean) => {
      if (isMobile) {
        setFullScreenOpen(open);
        setCanScroll(!open);
      }
    },
    [isMobile, fullScreenOpen, setFullScreenOpen]
  );

  useEffect(() => {
    const handler = () => {
      const isMobile = checkIsMobile();
      setIsMobile(isMobile);
      if (!isMobile) {
        toggleFullScreen(false);
      }
    };
    setTimeout(() => setIsMobile(checkIsMobile()));
    window.addEventListener('resize', handler);
    return () => window.removeEventListener('resize', handler);
  }, []);

  return (
    <>
      <div
        className="relative w-full md:w-[240px] h-full md:h-[440px]"
        onClick={() => toggleFullScreen(true)}
      >
        <Inner member={member} />
      </div>
      {fullScreenOpen && isMobile && (
        <div
          className="fixed md:hidden top-0 bottom-0 left-0 right-0 z-50 bg-secondary p-6 overflow-y-scroll"
          onClick={() => toggleFullScreen(false)}
        >
          <Inner member={member} isFullScreenPreview />
        </div>
      )}
    </>
  );
};
