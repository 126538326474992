import classNames from 'classnames';
import React from 'react';
import CenteredContainer from './centered-container';

const NarrowCenteredContainer = React.forwardRef<
  HTMLDivElement,
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
>(({ children, className }, ref) => {
  return (
    <CenteredContainer
      className={classNames(className, 'max-w-nxxl')}
      ref={ref}
    >
      {children}
    </CenteredContainer>
  );
});

export default NarrowCenteredContainer;
  