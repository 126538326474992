import { EMAIL_REGEX } from './regex';

export const EMAIL_VALIDATION_RULES = {
  name: {
    required: true,
    maxLength: 100,
  },
  email: {
    required: true,
    maxLength: 200,
    regex: EMAIL_REGEX,
  },
  phone: {
    required: false,
    maxLength: 100,
  },
  company: {
    required: false,
    maxLength: 100,
  },
  message: {
    required: true,
    maxLength: 5000,
  },
  file: {
    maxSize: 2 * 1024 ** 2,
    acceptedMimeTypes: [
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/rtf',
      'application/pdf',
    ],
  },
};
